import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import Seo from '../../components/seo/seo';
import '../styles/index.scss';

const blog = ({ data }) => {

  console.log(data)

  return (
    <>
      <div className="background-theme">
        <Container className="pt-5 pb-5 internal-pages-common blog">
          <Row>
            <h1>{data.allDatoCmsBlogPage.nodes[0].infoPage.title}</h1>
            {
              data.allDatoCmsBlogPage.nodes[0].posts?.map((blogPost, index) =>
                <Col className="mb-3 blog-columns" xs={12} md={4} key={index}>
                  {/* <StructuredText data={blogPost.shortDescription} /> */}
                  <a href={"/blog/" + blogPost.slugPost}>
                    <figure className="card-figure-top border-radius-2">
                      <img className='border-radius-2' alt="card-long-first" src={blogPost.image.url} />
                    </figure>
                  </a>
                  <h3>{blogPost.postTitle}</h3>
                  <p>{blogPost.shortDescription}</p>
                  <a href={"/blog/" + blogPost.slugPost}>read more {'>>'}</a>
                </Col>
              )
            }

          </Row>
        </Container>
        <div className='blue-footer'>
          <Container>
            <Row>
              <Col className="blog-paragraph" xs={12} md={8}>
                <p>{data.allDatoCmsBlogPage.nodes[0].paragrahp1}</p>
                <p>{data.allDatoCmsBlogPage.nodes[0].paragrahp2}</p>
              </Col>
              <Col xs={12} md={4}>
                <iframe title='videoUrl' width="420" height="315" src={data.allDatoCmsBlogPage.nodes[0].videoUrl}>
                </iframe>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}
export default blog

export const Head = ({ data }) => (

  <>
    <Seo title="Blog"
      description={data.allDatoCmsBlogPage.nodes[0].seo.description}
      image={data.allDatoCmsBlogPage.nodes[0].seo.image.url}
    />
  </>
)

export const query = graphql`
query BlogQuery {
  allDatoCmsBlogPage {
    nodes {
      seoMetaTags {
        tags
      }
      seo {
        title
        description
        image {
          url
        }
      }
      infoPage {
        slug
        title
      }
      posts {
        postTitle
        author
        slugPost
        image {
          url
        }
        shortDescription
        bodyPost {
          value
        }
        publishDate
      }
      paragrahp1
      paragrahp2
      videoUrl
    }
  }
}
`